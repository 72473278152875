:root {
  --theme-color         : #f63c25;
  --title-color         : #15171b;
  --body-color          : #818790;
  --smoke-color         : rgb(249, 249, 249);
  --smoke-dark          : rgb(242, 242, 242);
  --theme-overlay       : rgba(246, 60, 37, .1);
  --light-color         : #818790;
  --black-color         : #000000;
  --white-color         : #ffffff;
  --yellow-color        : #ffcc00;
  --success-color       : #28a745;
  --error-color         : #dc3545;
  --border-color        : rgba(0,0,0,0.10);
  --border-white        : rgba(255,255,255,.10);
  --title-font          : 'Roboto', sans-serif;
  --body-font           : 'Roboto', sans-serif;
  --subtitle-font       : 'Roboto', sans-serif;
  --icon-font           : 'Font Awesome 5 Pro';
  --main-container      : 1220px;
  --container-gutters   : 1.5rem;
  --section-space       :  150px;
  --section-space-mobile:  80px;
  --section-title-space :  80px;
  --ripple-ani-duration : 5s;
}


// Color Variation
$theme-color          : var(--theme-color);
$title-color          : var(--title-color);
$body-color           : var(--body-color);
$smoke-color          : var(--smoke-color);
$smoke-dark           : var(--smoke-dark);
$theme-overlay        : var(--theme-overlay);
$light-color          : var(--light-color);
$white-color          : var(--white-color);
$black-color          : var(--black-color);
$yellow-color         : var(--yellow-color);
$success-color        : var(--success-color);
$error-color          : var(--error-color);
$border-color         : var(--border-color);
$border-white         : var(--border-white);

// Font Variation
$icon-font   : var(--icon-font);

// Typography
$title-font      : var(--title-font);
$body-font       : var(--body-font);
$subtitle-font   : var(--subtitle-font);
$body-font-size  : 16px;
$body-line-Height: 26px;
$body-font-weight: 400;
$p-line-Height   : 1.8;


// Device Variation
$hd: 1921px; // Large Device Than 1920
$xl: 1500px; // Extra large Device
$ml: 1399px; // Medium Large Device
$lg: 1199px; // Large Device (Laptop)
$md: 991px; // Medium Device (Tablet)
$sm: 767px; // Small Device
$xs: 575px; // Extra Small Device
$vxs: 414px; // Very Extra Small Device


// Spacing Count with 5x
$space-count: 10;

// Section Space  For large Device
$space         : var(--section-space);
$space-negative: calc(var(--section-space) - 50px);
$double-space  : calc(var(--section-space) + var(--section-space));
$full-negative : calc(0px - var(--section-space));

// Section Space On small Device
$space-mobile         : var(--section-space-mobile);
$space-mobile-negative: calc(var(--section-space-mobile) - 20px);


// BG Color Mapping 
$bgcolorMap  : ();
$bgcolorMap  : map-merge((
  "theme"    : $theme-color,
  "smoke"    : $smoke-color,
  "smokedark": $smoke-dark,
  "white"    : $white-color,
  "black"    : $black-color,
  "title"    : $title-color,
), $bgcolorMap);


// Overlay Color Mapping 
$overlaycolorMap : ();
$overlaycolorMap : map-merge((
  "theme"        : $theme-color,
  "title"        : $title-color,
  "white"        : $white-color,
  "black"        : $black-color,
), $overlaycolorMap);


// Text Color Mapping 
$textColorsMap : ();
$textColorsMap : map-merge((
  "theme"      : $theme-color,
  "title"      : $title-color,
  "body"       : $body-color,
  "light"      : $light-color,
  "white"      : $white-color,
  "yellow"     : $yellow-color,
  "success"    : $success-color,
  "error"      : $error-color), 
$textColorsMap);


// Font Mapping 
$fontsMap    : ();
$fontsMap    : map-merge((
  "icon"     : $icon-font,
  "title"    : $title-font,
  "body"     : $body-font,
  "subtitle" : $subtitle-font,
), $fontsMap);