@each $bgcolorMap, $value in $bgcolorMap {
  .bg-#{$bgcolorMap} {
    background-color: #{$value} !important;
  }
}

.bg-dblue {
  background-color: #0b2e4e;
}


.background-image,
[data-bg-src] {
  @include background-content(no-repeat, cover, center center);
}

.mask-cover {
  mask-position: center center;
  mask-repeat: no-repeat;
  mask-size: 100%;
}

.bg-fluid {
  @include background-content(no-repeat, 100% 100%, center center);
}

.bg-auto {
  background-size: auto auto;
}

.bg-contain {
  background-size: contain;
}

.bg-top-right {
  background-position: top right;
  background-size: auto;
}

.bg-top-left {
  background-position: left top;
}

.bg-b-left {
  background-position: bottom left;
}

.bg-fixed {
  background-attachment: fixed;
}